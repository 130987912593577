import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box, BoxProps, FormControl, MenuItem, Select, SelectProps, styled } from '@mui/material';
import SearchIcon from '../icons/SearchIcon';
import { Input } from '../atoms/Input';
import { useTranslation } from 'react-i18next';
import { useTableContext } from '../../contexts/TableContext';
import debounce from 'lodash.debounce';
import { tableConfig } from '../../constants/tableConfig';
import { SearchField } from '../../types/tableFilters';

const SearchContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: '3rem',
    alignItems: 'center',
    marginLeft: '1.5rem',
    border: `0.07rem solid ${theme.palette.info[800]}`,
    borderRadius: '0.57rem',
    paddingTop: '0.85rem',
    paddingBottom: '0.85rem',
    paddingLeft: '1.07rem',
    paddingRight: '2.14rem',
}));

const StyledSelect = styled(Select)(() => ({
    height: '3rem',
    width: '7rem',
}));

const SearchBar: React.FC<BoxProps> = (props) => {
    const { t } = useTranslation();

    const [searchField, setSearchField] = useState<SearchField>(SearchField.Email);
    const [searchValue, setSearchValue] = useState<string>('');

    const { onSearch } = useTableContext();

    const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>(({ target: { value } }) => setSearchValue(value), []);

    const debouncedOnChange = debounce(onChange, tableConfig.searchDebounceTime);

    const onSearchFieldChange = useCallback<NonNullable<SelectProps['onChange']>>(
        (event) => {
            setSearchField(event.target.value as SearchField);
            searchValue && onSearch(searchValue, event.target.value as SearchField);
        },
        [onSearch, searchValue]
    );

    useEffect(() => {
        onSearch(searchValue, searchField);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSearch, searchValue]);

    const inputPlaceholder = searchField === SearchField.Email ? t('input.email') : t('input.id');

    return (
        <Box display="flex" alignItems="center" {...props}>
            <FormControl>
                <StyledSelect value={searchField} onChange={onSearchFieldChange}>
                    <MenuItem value={SearchField.Id}>{SearchField.Id}</MenuItem>
                    <MenuItem value={SearchField.Email}>{SearchField.Email}</MenuItem>
                </StyledSelect>
            </FormControl>

            <SearchContainer>
                <SearchIcon />

                <Input placeholder={inputPlaceholder} autoComplete="off" onChange={debouncedOnChange} />
            </SearchContainer>
        </Box>
    );
};

export default memo(SearchBar);
