import { useOutletContext } from 'react-router-dom';

function useOutletContextSafe<T>(guard: (value: unknown) => value is T) {
    const context = useOutletContext<unknown>();

    if (!guard(context)) {
        throw new Error('Outlet context is not set');
    }

    return context;
}

export default useOutletContextSafe;
