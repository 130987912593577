import { styled, TableCell as MuiTableCell, tableCellClasses } from '@mui/material';

const TableHeaderCell = styled(MuiTableCell)(({ theme }) => ({
    border: 0,
    borderBottom: `2px ${theme.palette.primary[600]} solid`,
    paddingTop: '1.428rem',
    paddingBottom: '1.428rem',
    paddingLeft: '1.357rem',
    paddingRight: '1.357rem',
    '&:first-of-type': {
        paddingLeft: '2.357rem',
    },
    '&:last-of-type div': {
        justifyContent: 'start',
    },
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary[900],
        h6: {
            color: theme.palette.info.main,
        },
    },
    userSelect: 'none',
}));

export default TableHeaderCell;
