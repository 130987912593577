enum TableColumnType {
    Id = 'table.id',
    AuthID = 'table.authId',
    Email = 'table.email',
    RegistrationDate = 'table.registrationDate',
    LastLogin = 'table.lastLogin',
    Properties = 'table.properties',
    FinAPIUserName = 'table.finAPIUserName',
    Actions = 'table.actions',
}

export default TableColumnType;
