import React, { memo, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import useToken from '../hooks/useToken';
import Home from '../pages/Home';
import Impersonate from '../pages/Impersonate';
import Login from '../pages/Login';
import Loading from '../pages/Loading';
import AuthorizationGuard from './AuthorizationGuard';
import { RoutesKeys } from './routes';
import Modal from '../components/atoms/Modal';
import Features from '../pages/Features';

const RootNavigator: React.FC = () => {
    const { accessToken } = useToken();

    const HomeElement = useMemo<JSX.Element>(() => <AuthorizationGuard authorized>{accessToken ? <Home /> : <Loading />}</AuthorizationGuard>, [accessToken]);

    const LoginElement = useMemo<JSX.Element>(() => <Login />, []);

    const ImpersonateElement = useMemo<React.ReactElement>(
        () => (
            <AuthorizationGuard authorized>
                <Impersonate />
            </AuthorizationGuard>
        ),
        []
    );

    const RedirectElement = useMemo<JSX.Element>(() => <Navigate to={RoutesKeys.Home} />, []);

    const FeatureElement = useMemo<React.ReactElement>(
        () => (
            <AuthorizationGuard authorized>
                <Modal>
                    <Features />
                </Modal>
            </AuthorizationGuard>
        ),
        []
    );

    return (
        <>
            <Routes>
                <Route path={RoutesKeys.Home} element={HomeElement}>
                    <Route path={RoutesKeys.Features} element={FeatureElement} />
                </Route>
                <Route path={RoutesKeys.Login} element={LoginElement} />
                <Route path={RoutesKeys.Impersonate} element={ImpersonateElement} />
                <Route path="*" element={RedirectElement} />
            </Routes>
        </>
    );
};

export default memo(RootNavigator);
