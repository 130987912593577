import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
    h1: { fontFamily: 'Roboto', fontWeight: 300, fontSize: '2.71rem' },
    h2: { fontFamily: 'Roboto', fontWeight: 400, fontSize: '2rem', lineHeight: '2.25rem', color: '#5E5E62' },
    h3: { fontFamily: 'Roboto', fontWeight: 400, fontSize: '1.71rem', lineHeight: '2rem', opacity: 0.42 },
    subtitle1: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.42rem',
        letterSpacing: '0.017rem',
    },
    subtitle2: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '1.28rem',
        lineHeight: '1.71rem',
    },
    body1: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '0.857rem',
        lineHeight: '1.14rem',
        letterSpacing: '0.028rem',
    },
    body2: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '1.14rem',
        letterSpacing: '0.035rem',
    },
    caption: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '0.785rem',
    },
    button: {
        textTransform: 'none',
    },
};

export default typography;
