import React from 'react';

/**
 * Takes a name of the context and returns an array: created context and useContext function
 * @param name a name of the context
 * @returns an array: created context and useContext function
 */
function createContext<T extends {}>(name: string): readonly [React.Context<T | undefined>, () => T] {
    const context = React.createContext<T | undefined>(undefined);

    const useContext = () => {
        const value = React.useContext(context);

        if (value === undefined) {
            throw new Error(`${name} context provider is missing`);
        }

        return value;
    };

    return [context, useContext];
}

export default createContext;
