import { styled, TableCell as MUITableCell } from '@mui/material';

const TableCell = styled(MUITableCell)(({ theme }) => ({
    border: 0,
    borderBottom: `1px ${theme.palette.primary[600]} solid`,
    paddingTop: '1.428rem',
    paddingBottom: '1.428rem',
    paddingLeft: '1.357rem',
    paddingRight: '1.357rem',
    '&:first-of-type': {
        paddingLeft: '2.357rem',
    },
    wordWrap: 'break-word',
}));

export default TableCell;
