import * as React from 'react';
import { SVGProps, memo } from 'react';

interface ArrowProps extends SVGProps<SVGSVGElement> {
    isActive: boolean;
}

const ArrowBottomIcon: React.FC<ArrowProps> = ({ isActive, ...props }) => {
    const width = isActive ? '1rem' : '0.714rem';

    const height = isActive ? '0.54rem' : '0.428rem';

    const viewBox = isActive ? '0 0 10 6' : undefined;

    return (
        <svg width={width} height={height} fill="none" viewBox={viewBox} style={style.arrow} {...props}>
            <path d="M5 2.83 8.17 6l1.41-1.41L5 0 .41 4.59 1.83 6" fill="#46464A" />
        </svg>
    );
};

const style: Record<string, React.CSSProperties> = {
    arrow: {
        marginTop: '0.428rem',
        transform: 'rotate(180deg)',
    },
};

export default memo(ArrowBottomIcon);
