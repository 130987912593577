import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationDE from './de.json';
import translationEN from './en.json';

const _i18n = i18n.createInstance();

_i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: translationEN,
        },
        de: {
            translation: translationDE,
        },
    },
    lng: 'de',
    fallbackLng: 'de',
    interpolation: {
        escapeValue: false,
    },
});

export default _i18n;
