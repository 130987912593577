import { styled, TableRow as MUITableRow } from '@mui/material';

const TableRow = styled(MUITableRow)(({ theme }) => ({
    border: 0,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
        backgroundColor: theme.palette.primary[900],
    },
}));

export default TableRow;
