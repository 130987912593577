import * as React from 'react';
import { SVGProps, memo } from 'react';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1.71rem" height="1.71rem" fill="none" {...props}>
        <path
            d="M15.755 14.255h-.79l-.28-.27a6.471 6.471 0 0 0 1.57-4.23 6.5 6.5 0 1 0-6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49-4.99-5Zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5Z"
            fill="#7D7573"
        />
    </svg>
);

export default memo(SearchIcon);
