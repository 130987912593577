import { InputBase, styled } from '@mui/material';

export const Input = styled(InputBase)(({ theme }) => ({
    width: '17rem',
    fontSize: '1rem',
    marginLeft: '1.14rem',

    input: {
        padding: 0,
        color: theme.palette.info[500],

        '&::placeholder': {
            color: theme.palette.info[500],
            opacity: 1,
        },
    },
}));
