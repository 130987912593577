import React from 'react';
import { ApolloProvider } from '@apollo/client';
import useApolloClient from '../hooks/useApolloClient';

const ApolloClientContext: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const client = useApolloClient();

    if (!client) return null;

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientContext;
