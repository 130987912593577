import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, styled, Typography, useTheme } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

interface ColumnOptionProps {
    columnName: string;
    isVisible: boolean;
    onSelect: (name: string, checked: boolean) => void;
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    justifyContent: 'space-between',
    margin: 0,
    paddingLeft: '1.14rem',
    paddingRight: '1.14rem',

    '&:hover': {
        backgroundColor: theme.palette.primary[900],
    },
}));

const ColumnOption: React.FC<ColumnOptionProps> = ({ columnName, isVisible, onSelect }) => {
    const { t } = useTranslation();

    const theme = useTheme();

    const checkboxIconStyle = useMemo<React.CSSProperties>(() => ({ color: theme.palette.info[600] }), [theme.palette.info]);

    const ActiveCheckboxIcon = useMemo<React.ReactNode>(() => <RemoveRedEyeOutlinedIcon style={checkboxIconStyle} />, [checkboxIconStyle]);
    const InactiveCheckboxIcon = useMemo<React.ReactNode>(() => <VisibilityOffOutlinedIcon style={checkboxIconStyle} />, [checkboxIconStyle]);

    const onSelectChange = useCallback<(event: React.ChangeEvent<HTMLInputElement>) => void>(
        (event) => {
            onSelect(event?.target.name, event.target.checked);
        },
        [onSelect]
    );

    const Label = useMemo<React.ReactElement>(
        () => (
            <Typography color="info.200" marginRight="0.57rem" variant="subtitle1" fontWeight={400}>
                {t(columnName)}
            </Typography>
        ),
        [columnName, t]
    );
    const Control = useMemo<React.ReactElement>(
        () => <Checkbox checked={isVisible} onChange={onSelectChange} name={columnName} icon={InactiveCheckboxIcon} checkedIcon={ActiveCheckboxIcon} />,
        [isVisible, onSelectChange, columnName, InactiveCheckboxIcon, ActiveCheckboxIcon]
    );

    return <StyledFormControlLabel key={`column-settings-${columnName}`} control={Control} label={Label} labelPlacement="start" />;
};

export default memo(ColumnOption);
