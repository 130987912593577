import * as R from 'ramda';
import TableColumnType from '../types/TableColumn';

export const columnsRatio = {
    [TableColumnType.Id]: 2.857,
    [TableColumnType.AuthID]: 3.428,
    [TableColumnType.Email]: 2.2856,
    [TableColumnType.RegistrationDate]: 1.642,
    [TableColumnType.LastLogin]: 1.428,
    [TableColumnType.Properties]: 1,
    [TableColumnType.FinAPIUserName]: 1.642,
    [TableColumnType.Actions]: 1.784,
};

export const defaultColumnsVisibility = {
    [TableColumnType.Id]: true,
    [TableColumnType.AuthID]: true,
    [TableColumnType.Email]: true,
    [TableColumnType.RegistrationDate]: true,
    [TableColumnType.LastLogin]: true,
    [TableColumnType.Properties]: true,
    [TableColumnType.FinAPIUserName]: true,
    [TableColumnType.Actions]: true,
};

export const tableConfig = {
    columnsRatio,
    pageSizeVariants: [5, 10, 15, 20, 25],
    searchDebounceTime: 700,
};
