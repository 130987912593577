import { Button as MUIButton, styled } from '@mui/material';

export const Button = styled(MUIButton)(() => ({
    paddingTop: '0.785rem',
    paddingBottom: '0.785rem',
    paddingLeft: '1.14rem',
    paddingRight: '1.14rem',
    borderRadius: '0.57rem',
    'span:first-of-type': {
        marginRight: 0,
    },
    'span + h6': {
        marginLeft: '0.714rem',
    },
}));
