import * as Z from 'zod';
import * as R from 'ramda';
import TableColumnType from './TableColumn';

const columnsRecordSchema = R.values(TableColumnType).reduce((a, e) => ({ ...a, [e]: Z.boolean() }), {} as Record<TableColumnType, Z.ZodBoolean>);

const tableConfigColumnsSchema = Z.object(columnsRecordSchema);

export const localStorageTableConfig = Z.object({
    columns: tableConfigColumnsSchema,
    pageSize: Z.number(),
});

export type TableConfigColumnsType = Z.infer<typeof tableConfigColumnsSchema>;

export type LocalStorageTableConfig = Z.infer<typeof localStorageTableConfig>;
