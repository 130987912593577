// @ts-ignore
const env = PAGE_CONF;

export const ENV = process.env.REACT_APP_ENV || env?.ENV;
export const API_URI = process.env.REACT_APP_API_URI || env?.API_URI;
export const BACKOFFICE_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN || env?.BACKOFFICE_AUTH_DOMAIN!;
export const BACKOFFICE_AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID || env?.BACKOFFICE_AUTH_CLIENT_ID;
export const IMPERSONATE_AUTH_DOMAIN = process.env.REACT_APP_IMPERSONATE_AUTH_DOMAIN || env?.IMPERSONATE_AUTH_DOMAIN!;
export const IMPERSONATE_AUTH_ID = process.env.REACT_APP_IMPERSONATE_AUTH_ID || env?.IMPERSONATE_AUTH_ID!;
export const IMPERSONATE_AUTH_REDIRECT_URL = process.env.REACT_APP_IMPERSONATE_AUTH_REDIRECT_URL || env?.IMPERSONATE_AUTH_REDIRECT_URL;
export const STRIPE_TRANSACTIONS_URL = process.env.REACT_APP_STRIPE_TRANSACTIONS_URI || env?.STRIPE_TRANSACTIONS_URI;
