import React, { memo, useEffect } from 'react';
import Loading from './Loading';
import { useImpersonateSearchParams } from '../hooks/useImpersonateSearchParams';
import impersonate from '../utils/impersonate';

const Impersonate: React.FC = () => {
    const { internalId, email, authIds } = useImpersonateSearchParams();

    useEffect(() => {
        if (internalId && email && authIds) {
            impersonate(internalId, email, authIds);
        }
    }, [internalId, email, authIds]);

    return <Loading />;
};

export default memo(Impersonate);
