import { useAnimation } from 'framer-motion';
import { useCallback, useEffect } from 'react';

const useModalAnimation = (onExit: () => void) => {
    const bgControls = useAnimation();
    const windowControls = useAnimation();

    const enterSequence = useCallback<() => Promise<void>>(async () => {
        bgControls.start({ opacity: 0.5 });
        await windowControls.start({ x: 0 });
    }, [bgControls, windowControls]);

    const exitSequence = useCallback<() => Promise<void>>(async () => {
        bgControls.start({ opacity: 0 });
        await windowControls.start({ x: '100%' });
        onExit();
    }, [bgControls, windowControls, onExit]);

    useEffect(() => {
        enterSequence();
    }, [enterSequence]);

    return { bgControls, windowControls, exitSequence };
};

export default useModalAnimation;
