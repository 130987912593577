import { Box, CssBaseline, styled } from '@mui/material';
import React, { memo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header/Header';
import RootNavigator from './navigation/RootNavigator';

const AppContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
}));

const App: React.FC = () => (
    <AppContainer>
        <BrowserRouter>
            <CssBaseline />
            <Header />
            <RootNavigator />
        </BrowserRouter>
    </AppContainer>
);

export default memo(App);
