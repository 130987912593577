import React, { memo } from 'react';
import { Stack, Typography } from '@mui/material';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import { useTranslation } from 'react-i18next';

const PaginationNextItem: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" alignItems="center">
            <Typography variant="body2" mr="0.9rem">
                {t('pagination.next')}
            </Typography>
            <ArrowRightIcon />
        </Stack>
    );
};

export default memo(PaginationNextItem);
