import { useState, useCallback } from 'react';

const useAnchor = () => {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    const onAnchorSet = useCallback<React.MouseEventHandler<HTMLElement>>(({ currentTarget }) => setAnchor(currentTarget), [setAnchor]);

    const onAnchorDestroy = useCallback<() => void>(() => setAnchor(null), [setAnchor]);

    return { anchor, onAnchorSet, onAnchorDestroy };
};

export default useAnchor;
