import { Box, BoxProps, FormGroup, Popover, PopoverProps, Typography } from '@mui/material';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useAnchor from '../../hooks/useAnchor';
import { useTableContext } from '../../contexts/TableContext';
import TableColumnType from '../../types/TableColumn';
import SettingsIcon from '../icons/SettingsIcon';
import { PopoverBox } from '../atoms/PopoverBox';
import ColumnsSelect from './ColumnsSelect';
import { Button } from '../shared/Button';

const anchorOrigin: PopoverProps['anchorOrigin'] = { vertical: 'bottom', horizontal: 'right' };

const transformOrigin: PopoverProps['transformOrigin'] = { vertical: 'top', horizontal: 'right' };

const SettingsStartIcon = <SettingsIcon color="info" />;

const ColumnSettings: React.FC<BoxProps> = (props) => {
    const { t } = useTranslation();

    const { columns, visibleColumns, setColumnVisibility } = useTableContext();

    const { anchor, onAnchorSet, onAnchorDestroy } = useAnchor();

    const onSelect = useCallback<(name: string, checked: boolean) => void>(
        (name, checked) => (visibleColumns.length === 2 && !checked) || setColumnVisibility(name as TableColumnType, checked),
        [visibleColumns, setColumnVisibility]
    );

    return (
        <Box {...props}>
            <Button variant="text" startIcon={SettingsStartIcon} aria-haspopup="true" onClick={onAnchorSet}>
                <Typography variant="subtitle1" ml="0.71rem" color="info.300" fontWeight={500}>
                    {t('button.columnSettings')}
                </Typography>
            </Button>

            <Popover id="menu-appbar" open={!!anchor} anchorEl={anchor} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} onClose={onAnchorDestroy}>
                <PopoverBox>
                    <FormGroup>
                        <ColumnsSelect onSelect={onSelect} columns={columns} />
                    </FormGroup>
                </PopoverBox>
            </Popover>
        </Box>
    );
};

export default memo(ColumnSettings);
