import React, { memo, useCallback, useEffect } from 'react';
import { motion, Target, Transition } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import useModalAnimation from '../../hooks/useModalAnimation';
import { Box, styled, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { isModalOutletContext } from '../../types/OutletContext';
import useOutletContextSafe from '../../hooks/useOutletContextSafe';

const TRANSITION: Transition = { type: 'linear' };

const BackButtonTypography = styled(Typography)({
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#5E5E62',
});

const BackButtonWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
});

const Modal: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { parentPath } = useOutletContextSafe(isModalOutletContext);

    const onExit = useCallback(() => {
        navigate(parentPath, { replace: true });
    }, [parentPath, navigate]);

    const { bgControls, windowControls, exitSequence } = useModalAnimation(onExit);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    });

    return (
        <motion.div style={styles.wrapper}>
            <motion.div transition={TRANSITION} initial={targets.background} animate={bgControls} onClick={exitSequence} style={styles.background} />
            <motion.div transition={TRANSITION} initial={targets.container} animate={windowControls} style={styles.container}>
                <BackButtonWrapper onClick={exitSequence} marginBottom="1.5rem" fontSize="1rem">
                    <ArrowBackIosIcon fontSize="inherit" />
                    <BackButtonTypography>{t('common.back')}</BackButtonTypography>
                </BackButtonWrapper>
                {children}
            </motion.div>
        </motion.div>
    );
};

const targets: Record<string, Target> = {
    background: { opacity: 0 },
    container: { x: '100%' },
};

const styles: Record<string, React.CSSProperties> = {
    wrapper: {
        top: 0,
        left: 0,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        zIndex: 1401,
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        overflow: 'hidden',
    },
    container: {
        borderRadius: '1.25rem 0 0 1.25rem',
        padding: '2.625rem',
        width: '50%',
        top: 0,
        right: 0,
        position: 'absolute',
        height: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
    },
};

export default memo(Modal);
