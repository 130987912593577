import React, { useCallback, memo } from 'react';
import { Avatar, Box, IconButton, PopoverProps, styled, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import useAnchor from '../../hooks/useAnchor';
import { Popover } from '../atoms/Popover';
import { useTranslation } from 'react-i18next';
import { Button } from '../shared/Button';

const anchorOrigin: PopoverProps['anchorOrigin'] = { vertical: 'bottom', horizontal: 'right' };

const transformOrigin: PopoverProps['transformOrigin'] = { vertical: 'top', horizontal: 'right' };

const StyledButton = styled(Button)(() => ({
    paddingTop: '0.428rem',
    paddingBottom: '0.428rem',
}));

const StyledPopoverBox = styled(Box)(({ theme }) => ({
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    padding: '1.71rem',
    backgroundColor: theme.palette.background.default,
}));

const AccountCircle = () => {
    const { t } = useTranslation();

    const { logout, user } = useAuth0();

    const { anchor, onAnchorSet, onAnchorDestroy } = useAnchor();

    const onSignOut = useCallback<() => void>(() => {
        logout({ returnTo: window.origin });
        localStorage.clear();
    }, [logout]);

    return (
        <Box>
            <IconButton aria-controls="menu-appbar" aria-haspopup="true" onClick={onAnchorSet}>
                <Avatar src={user?.picture} />
            </IconButton>

            <Popover id="menu-appbar" open={!!anchor} anchorEl={anchor} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} onClose={onAnchorDestroy}>
                <StyledPopoverBox>
                    <Typography variant="h2" color="info.100">
                        {user?.name}
                    </Typography>
                    <Typography variant="subtitle1" mt="1.14rem" mb="3.21rem" color="info.300">
                        {user?.email}
                    </Typography>
                    <StyledButton variant="contained" onClick={onSignOut} fullWidth>
                        <Typography variant="subtitle1" fontWeight={500}>
                            {t('button.signOut')}
                        </Typography>
                    </StyledButton>
                </StyledPopoverBox>
            </Popover>
        </Box>
    );
};

export default memo(AccountCircle);
