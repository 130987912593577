import { useEffect, useState } from 'react';
import { ApolloClient, from } from '@apollo/client';
import cache from '../utils/apolloCache';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { makeAuthLink, makeErrorLink, makeHttpLink } from '../utils/apolloLinks';
import { API_URI } from '../constants/env';
import useToken from './useToken';

const storage = new LocalStorageWrapper(window.localStorage);

const client = new ApolloClient({ cache });

const useApolloClient = () => {
    const [isPersisted, setIsPersisted] = useState<boolean>(false);

    const { accessTokenRef } = useToken();

    useEffect(() => {
        persistCache({ cache, storage }).then(() => setIsPersisted(true));
    }, []);

    useEffect(() => {
        client.setLink(from([makeErrorLink(() => accessTokenRef.current), makeAuthLink(() => accessTokenRef.current), makeHttpLink(API_URI)]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isPersisted ? client : null;
};

export default useApolloClient;
