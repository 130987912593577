import { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useToken = () => {
    const { getAccessTokenSilently, isLoading, loginWithRedirect } = useAuth0();

    const [accessToken, setAccessToken] = useState<string | null>(null);
    const accessTokenRef = useRef<string | null>(null);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const setUpToken = async () => {
            try {
                const { id_token } = await getAccessTokenSilently({ detailedResponse: true });

                if (id_token) {
                    setAccessToken(id_token);
                    accessTokenRef.current = id_token;
                }
            } catch (error) {
                console.error(error);
            }
        };

        setUpToken();
    }, [getAccessTokenSilently, isLoading, loginWithRedirect]);

    return { accessToken, accessTokenRef };
};

export default useToken;
