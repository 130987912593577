import { WebAuth } from 'auth0-js';
import { IMPERSONATE_AUTH_DOMAIN, IMPERSONATE_AUTH_ID, IMPERSONATE_AUTH_REDIRECT_URL } from '../constants/env';

/**
 * Takes a user data and call authorize method of the auth object from auth0-js library
 * @param userId string
 * @param email string
 * @param authIds array of strings
 * @returns void
 */
const impersonate: (userId: string, email: string, authIds: string[]) => void = (userId, email, authIds) => {
    const auth = new WebAuth({
        domain: IMPERSONATE_AUTH_DOMAIN,
        clientID: IMPERSONATE_AUTH_ID,
        redirectUri: IMPERSONATE_AUTH_REDIRECT_URL,
        scope: `openid email`,
        responseType: `token id_token`,
    });

    const options: Record<string, any> = {
        user_metadata: {
            impersonate_user_id: userId,
            impersonate_email: email,
            impersonate_auth_ids: (authIds || []).join(';'),
        },
    };

    auth.authorize(options);
};

export default impersonate;
