import { PaletteOptions } from '@mui/material';

declare module '@mui/material/styles/createPalette' {
    interface PaletteColor extends ColorPartial {}
}

const primaryPalette: PaletteOptions['primary'] = {
    main: '#3572E2',
    100: '#E1E2EC',
    400: '#0558C8',
    600: '#D6DEFF',
    800: '#AEC6FF',
    900: '#ECF0FF',
};

const infoPalette: PaletteOptions['info'] = {
    main: '#000',
    100: '#1B1B1E',
    200: '#2E3038',
    300: '#46464A',
    400: '#635C5A',
    500: '#7D7573',
    600: '#5E5E62',
    700: 'rgba(94, 94, 98, 0.42)',
    800: '#C8C6CA',
    900: '#919094',
    A100: '#E9EBEE',
};

const palette: PaletteOptions = {
    primary: primaryPalette,
    info: infoPalette,
    background: {
        default: '#FFF',
    },
    common: {
        white: '#FDFDFD',
    },
    success: {
        main: '#02885C',
        600: '#016947',
    },
    error: {
        main: '#F33D02',
        400: '#BA1B1B',
    },
    warning: {
        main: '#F33D02',
        dark: '#BA1B1B',
    },
    secondary: {
        main: '#fff',
        '100': '#F7D600',
        '200': '#ADD2C2',
        '300': '#DD99BB',
    },
};

export default palette;
