import React, { memo, useMemo } from 'react';
import * as R from 'ramda';
import { Box, styled, Typography } from '@mui/material';
import AccountCircle from './AccountCircle';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation, matchRoutes } from 'react-router-dom';
import { RoutesKeys } from '../../navigation/routes';
import { useTranslation } from 'react-i18next';
import ColumnSettings from '../ColumnSettings/ColumnSettings';
import SearchBar from './SearchBar';

const HeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '4.07rem',
    paddingRight: '4.42rem',
    paddingBottom: '1.5rem',
    paddingLeft: '3rem',
    borderBottom: `1px solid ${theme.palette.primary[100]}`,
    backgroundColor: theme.palette.background.default,
    zIndex: 1400,
}));

const StyledLink = styled(Link)(() => ({
    textDecoration: 'none',
}));

const HeaderText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary[400],
    userSelect: 'none',
}));

const stickyHeaderStyle: React.CSSProperties = {
    position: 'sticky',
    left: 0,
    right: 0,
    top: 0,
};

const Header: React.FC = () => {
    const { t } = useTranslation();

    const location = useLocation();

    const { user } = useAuth0();

    const homeElementStyle = useMemo<React.CSSProperties>(() => {
        const isVisible = !!matchRoutes([RoutesKeys.Home, RoutesKeys.Features].map(R.objOf('path')), location);

        return { visibility: isVisible ? 'visible' : 'hidden' };
    }, [location]);

    if (!user) {
        return null;
    }

    return (
        <HeaderContainer style={stickyHeaderStyle}>
            <Box display="flex" alignItems="center">
                <StyledLink to={RoutesKeys.Home}>
                    <HeaderText variant="h1">{t('projectName')}</HeaderText>
                </StyledLink>
                <SearchBar marginLeft="2.28rem" style={homeElementStyle} />
            </Box>
            <Box display="flex" alignItems="center">
                <ColumnSettings style={homeElementStyle} />
                <AccountCircle />
            </Box>
        </HeaderContainer>
    );
};

export default memo(Header);
