import React, { useCallback, useMemo } from 'react';
import { Table as MUITable, TableBody as MUITableBody, TableHead, TableProps as MUITableProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loading from '../../pages/Loading';
import EmptyState from '../../pages/EmptyState';
import TableHeaderCell from './TableHeaderCell';
import TableRow from '../atoms/TableRow';
import { getColGroupSum, getColumnWidth } from '../../utils/table';

interface TableProps<T extends string, U> extends MUITableProps {
    loading: boolean;
    columns: T[];
    columnsRatio: Record<T, number>;
    rows: U[];
    renderRow: (data: U, index: number) => React.ReactElement;
    renderHeaderCell?: (key: T) => React.ReactElement;
}

function Table<T extends string, U>({ loading, columns, columnsRatio, rows, renderRow, renderHeaderCell, ...props }: TableProps<T, U>) {
    const { t } = useTranslation();

    const renderCol = useCallback<(columnName: T, index: number) => React.ReactNode>(
        (columnName, index) => {
            const colGroupSum = getColGroupSum<T>(columns, columnsRatio);
            const width = getColumnWidth((columnsRatio[columnName] / colGroupSum) * 100);

            return <col key={index} width={width} />;
        },
        [columns, columnsRatio]
    );

    const renderDefaultHeaderCell = useCallback<(key: T) => React.ReactElement>(
        (key) => (
            <TableHeaderCell key={key}>
                <Typography variant="subtitle1">{t(key)}</Typography>
            </TableHeaderCell>
        ),
        [t]
    );

    const ColGroup = useMemo<React.ReactElement>(() => <colgroup>{columns.map(renderCol)}</colgroup>, [renderCol, columns]);

    const Cells = useMemo<React.ReactElement[]>(
        () => columns.map(renderHeaderCell ?? renderDefaultHeaderCell),
        [renderHeaderCell, renderDefaultHeaderCell, columns]
    );

    const Content = useMemo<React.ReactElement[]>(() => rows.map(renderRow), [rows, renderRow]);

    if (loading) {
        return <Loading />;
    }

    if (!rows.length) {
        return <EmptyState />;
    }

    return (
        <MUITable {...props}>
            {ColGroup}
            <TableHead>
                <TableRow>{Cells}</TableRow>
            </TableHead>
            <MUITableBody>{Content}</MUITableBody>
        </MUITable>
    );
}

export default Table;
