import * as R from 'ramda';
import React, { memo, useCallback, useMemo } from 'react';
import TableColumnType from '../../types/TableColumn';
import { TableConfigColumnsType } from '../../types/tableConfig';
import ColumnOption from './ColumnOption';

interface ColumnsSelectProps {
    columns: TableConfigColumnsType;
    onSelect: (name: string, checked: boolean) => void;
}

const ColumnsSelect: React.FC<ColumnsSelectProps> = ({ onSelect, columns }) => {
    const renderOption = useCallback<(isVisible: boolean, columnName: string) => React.ReactNode>(
        (isVisible, columnName) =>
            columnName !== TableColumnType.Actions && <ColumnOption columnName={columnName} isVisible={isVisible} onSelect={onSelect} key={columnName} />,
        [onSelect]
    );

    const Items = useMemo<React.ReactNode[]>(() => R.values(R.mapObjIndexed(renderOption, columns)), [columns, renderOption]);

    return <>{Items}</>;
};

export default memo(ColumnsSelect);
