import * as R from 'ramda';
import { RoutesKeys } from '../navigation/routes';

/**
 * Takes an accumulator, values pair and an element index and returns a part of a search params string
 * @param acc string
 * @param value values pair [string, string]
 * @param index pair index
 * @returns part of a search params string
 */
export const concatKeyValuePair = (acc: string, value: [string, string], index: number): string => acc + (index === 0 ? '?' : '&') + value[0] + '=' + value[1];

/**
 * Takes a search params object and return a generated search params string
 * @param params search params object
 * @returns search params string
 */
export const makeSearchParams = (params: { [key: string]: string }): string =>
    R.addIndex<[string, string], string>(R.reduce)(concatKeyValuePair, '', R.toPairs(params));
