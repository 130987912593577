import React, { memo, useCallback, useMemo, useState } from 'react';
import * as R from 'ramda';
import { useParams } from 'react-router-dom';
import { useCreateFeatureMutation, useFeaturesQuery } from '../graphql';
import { Box, FormGroup, InputBase, styled, Typography } from '@mui/material';
import FeaturesListItem from '../components/Features/FeaturesListItem';
import Loading from './Loading';
import { knownFeatures } from '../constants/features';
import { useTranslation } from 'react-i18next';
import { ENV } from '../constants/env';
import useUpdateFragment from '../hooks/useUpdateFragment';
import { Button } from '../components/shared/Button';

const StyledInput = styled(InputBase)(({ theme }) => ({
    flex: 1,
    input: {
        fontSize: '1.14rem',
        borderRadius: '0.57rem',
        backgroundColor: theme.palette.info.A100,
        padding: 0,
        color: theme.palette.info[500],
        paddingTop: '0.71rem',
        paddingBottom: '0.71rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        '&::placeholder': {
            color: theme.palette.info[500],
            opacity: 1,
        },
    },
}));

const FeaturesDivider = styled(Box)(({ theme }) => ({
    height: '0.07rem',
    alignSelf: 'stretch',
    backgroundColor: theme.palette.primary[100],
}));

const Features: React.FC = () => {
    const { t } = useTranslation();

    const { userId } = useParams();

    const [unknownFeatureName, setUnknownFeatureName] = useState<string>('');

    const { data, loading } = useFeaturesQuery({
        variables: { id: userId ?? '' },
        skip: !userId,
        fetchPolicy: 'cache-and-network',
    });

    const { onCreateFeature } = useUpdateFragment();

    const [create, { loading: createLoading }] = useCreateFeatureMutation({
        onCompleted: onCreateFeature,
    });

    const onCreateUnknownFeature = useCallback<() => void>(() => {
        if (userId) {
            const options = { variables: { id: userId, name: unknownFeatureName } };

            create(options);
            setUnknownFeatureName('');
        }
    }, [create, unknownFeatureName, userId]);

    const onInputChange = useCallback<React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>((event) => {
        setUnknownFeatureName(event.target.value);
    }, []);

    const FeatureInput = useMemo<React.ReactElement>(
        () => (
            <Box flex={1} marginBottom="2.14rem" display="flex">
                <Box flex={1} marginRight="1.71rem" display="flex">
                    <StyledInput placeholder={t('features.addFeature')} value={unknownFeatureName} onChange={onInputChange} disabled={createLoading} />
                </Box>

                <Button variant="contained" disabled={createLoading || !unknownFeatureName} onClick={onCreateUnknownFeature}>
                    <Typography variant="subtitle1" fontWeight={500}>
                        {t('button.addFeature')}
                    </Typography>
                </Button>
            </Box>
        ),
        [createLoading, onCreateUnknownFeature, onInputChange, t, unknownFeatureName]
    );

    const renderItem = useCallback<(featureKey: string, index: number) => React.ReactElement>(
        (featureKey, index) => {
            const checked = R.includes(featureKey, data?.landlord?.features ?? []);
            const showDivider = index > 0;

            return (
                <Box display="flex" flexDirection="column" key={featureKey}>
                    {showDivider && <FeaturesDivider />}
                    <FeaturesListItem checked={checked} feature={featureKey} />
                </Box>
            );
        },
        [data?.landlord?.features]
    );

    const Items = useMemo<React.ReactElement[]>(() => {
        const landlordFeatures = data?.landlord?.features.filter(R.complement(R.includes(R.__, knownFeatures))) ?? [];

        return knownFeatures.concat(landlordFeatures).map(renderItem);
    }, [data?.landlord?.features, renderItem]);

    if (loading || !data) {
        return <Loading />;
    }

    return (
        <Box overflow="scroll" pr="0.7rem">
            <Typography variant="h2" marginBottom="2.875rem" marginTop="2rem">
                {userId}
            </Typography>

            {FeatureInput}

            <Typography variant="h3" marginBottom="1.25rem">
                {t('features.name')}
            </Typography>
            <FormGroup>{Items}</FormGroup>
        </Box>
    );
};

export default memo(Features);
