import { Box, Typography } from '@mui/material';
import React, { memo } from 'react';
import TableCell from '../atoms/TableCell';

interface TableIdCellProps {
    id: string;
    isUpmin?: boolean;
    isUnpaid?: boolean;
}

const TableIdCell: React.FC<TableIdCellProps> = ({ id }) => {
    // const renderUserBadge = useCallback<(item: Badge, index: number) => React.ReactElement>(
    //     (item, index) => <LandlordBadge variant={item} index={index} />,
    //     []
    // );

    // const UserBadges = useMemo<React.ReactElement[]>(() => badgesMock.map(renderUserBadge), [renderUserBadge]);

    return (
        <TableCell>
            <Box display="flex" alignItems="center">
                <Typography variant="subtitle1" color="info.300">
                    {id}
                </Typography>

                {/* <Box display="flex" flexDirection="column" marginLeft="1rem">
                    {UserBadges}
                </Box> */}
            </Box>
        </TableCell>
    );
};

export default memo(TableIdCell);
