import * as React from 'react';
import { SVGProps, memo } from 'react';

const ThreeDotsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1.14rem" height="0.285rem" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2Zm-2 0c0-1.1-.9-2-2-2S6 .9 6 2s.9 2 2 2 2-.9 2-2ZM2 0c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2Z"
            fill="#5E5E62"
        />
    </svg>
);

export default memo(ThreeDotsIcon);
