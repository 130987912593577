import React, { memo } from 'react';
import { Stack, Typography } from '@mui/material';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import { useTranslation } from 'react-i18next';

const PaginationPrevItem: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" alignItems="center">
            <ArrowLeftIcon />
            <Typography variant="body2" ml="0.9rem">
                {t('pagination.prev')}
            </Typography>
        </Stack>
    );
};

export default memo(PaginationPrevItem);
