import React, { memo, useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { LandlordFragment } from '../../graphql';
import TableRow from '../atoms/TableRow';
import TableColumnType from '../../types/TableColumn';
import TableIdCell from './TableIdCell';
import TableDefaultCell from '../shared/TableDefaultCell';
import LandlordActionsContent from './LandlordActionsContent';
import useAnchor from '../../hooks/useAnchor';
import ImpersonateIcon from '../icons/ImpersonateIcon';
import { RoutesKeys } from '../../navigation/routes';
import { Link, To } from 'react-router-dom';
import { makeSearchParams } from '../../utils/navigation';
import { styled, Box } from '@mui/material';
import TableActionsButton from '../shared/TableActionsButton';
import TableCell from '../atoms/TableCell';
import { useTableContext } from '../../contexts/TableContext';
import { useTranslation } from 'react-i18next';
import { Button } from '../shared/Button';

interface LandlordTableRowProps {
    row: LandlordFragment;
}

const StyledButton = styled(Button)(() => ({
    minWidth: 'auto',
    padding: '0.428rem',
    marginRight: '0.857rem',
    borderRadius: '0.285rem',
}));

const LandlordTableRow: React.FC<LandlordTableRowProps> = ({ row }) => {
    const { t } = useTranslation();

    const { anchor, onAnchorSet, onAnchorDestroy } = useAnchor();

    const { visibleColumns } = useTableContext();

    const toImpersonate = useMemo<To>(
        () => ({
            pathname: RoutesKeys.Impersonate,
            search: makeSearchParams({
                internalId: row?.id ?? '',
                email: row?.email ? encodeURIComponent(row.email) : '',
                authIds: JSON.stringify(row?.authIds),
            }),
        }),
        [row?.authIds, row?.email, row?.id]
    );

    const ActionButton = useMemo<React.ReactElement>(
        () => (
            <Link to={toImpersonate} target="_blank">
                <StyledButton variant="outlined">
                    <ImpersonateIcon />
                </StyledButton>
            </Link>
        ),
        [toImpersonate]
    );

    const renderCell = useCallback<(key: TableColumnType, index: number) => React.ReactElement>(
        (key, index) => {
            const authIds = R.join(', ', row.authIds);
            const createdAt = new Date(row.createdAt).toDateString().toString();
            const lastLogin = new Date(row.lastLogin).toDateString().toString();

            switch (key) {
                case TableColumnType.Id:
                    return <TableIdCell id={row.id} key={index} />;
                case TableColumnType.AuthID:
                    return <TableDefaultCell value={authIds} key={index} />;
                case TableColumnType.Email:
                    return <TableDefaultCell value={row.email} key={index} />;
                case TableColumnType.RegistrationDate:
                    return <TableDefaultCell value={createdAt} key={index} />;
                case TableColumnType.LastLogin:
                    return <TableDefaultCell value={lastLogin} key={index} />;
                case TableColumnType.Properties:
                    return <TableDefaultCell value={row.propertiesCount} key={index} />;
                case TableColumnType.FinAPIUserName:
                    return <TableDefaultCell value={row.finApiUserName ?? t('table.emptyField')} key={index} />;
                case TableColumnType.Actions:
                    return (
                        <TableCell key={index}>
                            <Box display="flex" justifyContent="center" alignItems="stretch">
                                {ActionButton}

                                <TableActionsButton anchor={anchor} onAnchorSet={onAnchorSet} onAnchorDestroy={onAnchorDestroy}>
                                    <LandlordActionsContent row={row} onAnchorDestroy={onAnchorDestroy} />
                                </TableActionsButton>
                            </Box>
                        </TableCell>
                    );
            }
        },
        [ActionButton, anchor, onAnchorDestroy, onAnchorSet, row, t]
    );

    const Cells = useMemo<React.ReactElement[]>(() => visibleColumns.map(renderCell), [renderCell, visibleColumns]);

    return <TableRow>{Cells}</TableRow>;
};

export default memo(LandlordTableRow);
