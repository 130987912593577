import React, { ChangeEvent, memo, useCallback, useEffect, useMemo } from 'react';
import { Box, Typography, Pagination, PaginationItem, PaginationProps, styled, Select, SelectChangeEvent, MenuItem } from '@mui/material';
import PaginationPrevItem from './PaginationPrevItem';
import PaginationNextItem from './PaginationNextItem';
import { tableConfig } from '../../constants/tableConfig';
import { useTableContext } from '../../contexts/TableContext';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)(({ theme }) => ({
    position: 'sticky',
    bottom: 0,
    paddingTop: '1.8rem',
    paddingBottom: '1.8rem',
    paddingLeft: '5.6rem',
    paddingRight: '5.6rem',
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
}));

const LandlordTablePagination: React.FC = () => {
    const { t } = useTranslation();

    const { page, loading, pageSize, setPage, setPageSize, pagesCount } = useTableContext();

    const selectPage = useCallback<(event: ChangeEvent<unknown>, pageValue: number) => void>((_, pageValue) => setPage(pageValue - 1), [setPage]);

    const selectRowsPerPage = useCallback<(event: SelectChangeEvent<number>) => void>((event) => setPageSize(event.target.value as number), [setPageSize]);

    const renderItem = useCallback<NonNullable<PaginationProps['renderItem']>>(
        (item) => <PaginationItem components={{ previous: PaginationPrevItem, next: PaginationNextItem }} {...item} />,
        []
    );

    const renderSelectItem = useCallback<(value: number) => React.ReactElement>(
        (value) => (
            <MenuItem value={value} key={value}>
                {value}
            </MenuItem>
        ),
        []
    );

    const SelectItems = useMemo<React.ReactElement[]>(() => tableConfig.pageSizeVariants.map(renderSelectItem), [renderSelectItem]);

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [page]);

    if (loading && !pagesCount) {
        return null;
    }

    return (
        <Container>
            <Box mr="3rem" display="flex" alignItems="center">
                <Typography variant="body2" fontWeight={400} mr="1rem">
                    {t('pagination.pageSize')}
                </Typography>
                <Select displayEmpty variant="standard" value={pageSize} onChange={selectRowsPerPage}>
                    {SelectItems}
                </Select>
            </Box>

            <Pagination count={pagesCount} page={page + 1} onChange={selectPage} siblingCount={0} shape="rounded" renderItem={renderItem} />
        </Container>
    );
};

export default memo(LandlordTablePagination);
