import { useCallback, useMemo } from 'react';
import * as R from 'ramda';
import useLocalStorage from 'use-local-storage';
import { TableContextType } from '../contexts/TableContext';
import { defaultColumnsVisibility, tableConfig } from '../constants/tableConfig';
import UseLocalStorageOptionsType from '../types/useLocalStorageOptions';
import { localStorageTableConfig, LocalStorageTableConfig } from '../types/tableConfig';
import TableColumnType from '../types/TableColumn';

const TABLE_CONFIG_STORAGE_KEY = 'table-config';

const DEFAULT_CONFIG = { pageSize: tableConfig.pageSizeVariants[3], columns: defaultColumnsVisibility };

const STORAGE_HOOK_OPTIONS: UseLocalStorageOptionsType<LocalStorageTableConfig> = {
    parser: (value) => {
        const parsingResult = localStorageTableConfig.safeParse(JSON.parse(value));

        if (parsingResult.success) {
            return parsingResult.data;
        }

        console.error('The table config is damaged. Default config restored');

        return DEFAULT_CONFIG;
    },
};

const useTableConfig = () => {
    const [tableConfig, setTableConfig] = useLocalStorage(TABLE_CONFIG_STORAGE_KEY, DEFAULT_CONFIG, STORAGE_HOOK_OPTIONS);

    const visibleColumns = useMemo<TableColumnType[]>(() => R.keys(R.filter(R.equals(true), tableConfig.columns)), [tableConfig.columns]);

    const setColumnVisibility = useCallback<TableContextType['setColumnVisibility']>(
        (key, visible) => setTableConfig(R.assocPath(['columns', key], visible)),
        [setTableConfig]
    );

    const setPageSize = useCallback<TableContextType['setPageSize']>((count) => setTableConfig(R.assocPath(['pageSize'], count)), [setTableConfig]);

    return { ...tableConfig, visibleColumns, setColumnVisibility, setPageSize };
};

export default useTableConfig;
