import { useAuth0 } from '@auth0/auth0-react';
import React, { memo } from 'react';
import { Navigate } from 'react-router-dom';
import { useCheckRightsQuery } from '../graphql';
import Loading from '../pages/Loading';
import NoRights from '../pages/NoRights';

export interface LoadingRouteProps {
    children: React.ReactElement | JSX.Element;
    loading: boolean;
    isValid: boolean;
    onInvalidPath: string;
}

const LoadingRoute: React.FC<LoadingRouteProps> = ({ children, loading, isValid, onInvalidPath }) => {
    const { user } = useAuth0();

    const { data, loading: rightsLoading } = useCheckRightsQuery({ variables: { email: user?.email ?? '' }, fetchPolicy: 'cache-and-network' });

    if (loading || (rightsLoading && isValid)) return <Loading />;

    if (!data?.checkRights && isValid) {
        return <NoRights />;
    }

    return isValid ? children : <Navigate to={onInvalidPath} />;
};

export default memo(LoadingRoute);
