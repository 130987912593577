import * as React from 'react';
import { SVGProps, memo } from 'react';

const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="0.714rem" height="1.14rem" fill="none" {...props}>
        <path d="M9.376 1.903 8.041.575.624 8l7.425 7.425 1.327-1.328L3.28 8l6.097-6.097Z" fill="#5E5E62" />
    </svg>
);

export default memo(ArrowLeftIcon);
