import { useSearchParams } from 'react-router-dom';

interface ImpersonateSearchParams {
    internalId: string;
    email: string;
    authIds: string[];
}

export const useImpersonateSearchParams = (): ImpersonateSearchParams => {
    const [search] = useSearchParams();

    const searchAsObject = Object.fromEntries(new URLSearchParams(search));

    return {
        internalId: searchAsObject.internalId ?? '',
        email: searchAsObject.email ?? '',
        authIds: searchAsObject.authIds ? JSON.parse(searchAsObject.authIds) : '',
    };
};
