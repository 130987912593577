import * as React from 'react';
import { SVGProps, memo } from 'react';

const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1.28rem" height="1.357rem" fill="none" {...props}>
        <path
            d="M14.574 10.235c.03-.24.053-.48.053-.735s-.023-.495-.053-.735l1.583-1.238a.378.378 0 0 0 .09-.48l-1.5-2.595a.375.375 0 0 0-.458-.165l-1.867.75a5.48 5.48 0 0 0-1.268-.734l-.285-1.988A.366.366 0 0 0 10.502 2h-3a.366.366 0 0 0-.368.315L6.85 4.303a5.762 5.762 0 0 0-1.267.734l-1.868-.75a.425.425 0 0 0-.135-.022.371.371 0 0 0-.322.188l-1.5 2.595a.37.37 0 0 0 .09.48l1.582 1.237c-.03.24-.052.487-.052.735s.022.495.052.735l-1.582 1.238a.378.378 0 0 0-.09.48l1.5 2.595a.375.375 0 0 0 .457.165l1.868-.75c.39.3.81.547 1.267.735l.285 1.987c.023.18.18.315.368.315h3a.366.366 0 0 0 .367-.315l.285-1.987a5.762 5.762 0 0 0 1.268-.736l1.867.75c.045.015.09.023.135.023a.371.371 0 0 0 .323-.188l1.5-2.595a.378.378 0 0 0-.09-.48l-1.583-1.237ZM13.09 8.953c.03.232.038.39.038.547 0 .158-.015.322-.038.547l-.105.848.668.525.81.63-.525.907-.953-.382-.78-.315-.675.51c-.322.24-.63.42-.937.548l-.795.322-.12.848-.15 1.012h-1.05l-.263-1.86-.795-.322a4.255 4.255 0 0 1-.922-.533l-.683-.525-.795.322-.952.383-.525-.908.81-.63.667-.524-.105-.848a6.033 6.033 0 0 1-.037-.555c0-.15.015-.322.037-.547l.105-.848-.667-.525-.81-.63.525-.907.952.382.78.315.675-.51c.323-.24.63-.42.938-.548l.795-.322.12-.848.15-1.012h1.042l.263 1.86.795.322c.322.135.622.308.922.533l.683.525.795-.322.952-.383.525.907-.802.638-.668.525.105.848ZM9.002 6.5a3 3 0 1 0 0 6 3 3 0 1 0 0-6Zm0 4.5c-.825 0-1.5-.675-1.5-1.5S8.177 8 9.002 8s1.5.675 1.5 1.5-.675 1.5-1.5 1.5Z"
            fill="#46464A"
        />
    </svg>
);

export default memo(SettingsIcon);
