import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import {
    CreateFeatureMutation,
    DeleteFeatureMutation,
    LandlordFragment,
    LandlordFragmentDoc,
    SetLandlordBlockedMutation,
    SetLandlordTestMutation,
} from '../graphql';

type OnMutationCompletedFunction<T> = (data: T) => void;

const LANDLORD_CACHE_KEY = 'Landlord';

const useUpdateFragment = () => {
    const client = useApolloClient();

    const onCreateFeature = useCallback<OnMutationCompletedFunction<CreateFeatureMutation>>(
        ({ createFeature: { id, features } }) => {
            client.cache.updateFragment<LandlordFragment>({ fragment: LandlordFragmentDoc, id: `${LANDLORD_CACHE_KEY}:${id}` }, (fragment) =>
                fragment ? { ...fragment, features } : null
            );
        },
        [client]
    );

    const onDeleteFeature = useCallback<OnMutationCompletedFunction<DeleteFeatureMutation>>(
        ({ deleteFeature: { id, features } }) => {
            client.cache.updateFragment<LandlordFragment>({ fragment: LandlordFragmentDoc, id: `${LANDLORD_CACHE_KEY}:${id}` }, (fragment) =>
                fragment ? { ...fragment, features } : null
            );
        },
        [client]
    );

    const onSetLandlordTest = useCallback<OnMutationCompletedFunction<SetLandlordTestMutation>>(
        ({ updateLandlord: { id, test } }) => {
            client.cache.updateFragment<LandlordFragment>({ fragment: LandlordFragmentDoc, id: `${LANDLORD_CACHE_KEY}:${id}` }, (fragment) =>
                fragment ? { ...fragment, test } : null
            );
        },
        [client]
    );

    const onSetLandlordBlocked = useCallback<OnMutationCompletedFunction<SetLandlordBlockedMutation>>(
        ({ blockLandlord: { id, blocked } }) => {
            client.cache.updateFragment<LandlordFragment>({ fragment: LandlordFragmentDoc, id: `${LANDLORD_CACHE_KEY}:${id}` }, (fragment) =>
                fragment ? { ...fragment, blocked } : null
            );
        },
        [client]
    );

    return {
        onCreateFeature,
        onDeleteFeature,
        onSetLandlordTest,
        onSetLandlordBlocked,
    };
};

export default useUpdateFragment;
