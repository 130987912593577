import { useCallback, useState } from 'react';

const useDialog = () => {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const onOpenDialog = useCallback<() => void>(() => {
        setIsOpened(true);
    }, []);

    const onCloseDialog = useCallback<() => void>(() => {
        setIsOpened(false);
    }, []);

    return { isOpened, onOpenDialog, onCloseDialog };
};

export default useDialog;
