import * as React from 'react';
import { SVGProps, memo } from 'react';

const FeaturesIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1.71rem" height="1.785rem" fill="none" {...props}>
        <path
            d="M20.5 2.966v2.02a13.413 13.413 0 0 0-8.5-3.02c-3.22 0-6.18 1.13-8.5 3.02v-2.02H2v5h5v-1.5H4.09c2.11-1.86 4.88-3 7.91-3 3.03 0 5.79 1.14 7.91 3H17v1.5h5v-5h-1.5Z"
            fill="#5E5E62"
        />
        <path
            d="m18.89 14.736-3.8-1.67c-.13-.06-.28-.1-.44-.1H14v-4.37c0-1.32-.96-2.5-2.27-2.62A2.508 2.508 0 0 0 9 8.466v8.15l-1.87-.4c-.19-.03-1.02-.15-1.73.56L4 18.186l5.12 5.19c.37.38.88.59 1.41.59h6.55c.98 0 1.81-.7 1.97-1.67l.92-5.44a2 2 0 0 0-1.08-2.12Zm-.89 1.79-.92 5.44h-6.55l-3.7-3.78 4.17.89V8.466c0-.28.22-.5.5-.5s.5.22.5.5v6.18h1.76l4.24 1.88Z"
            fill="#5E5E62"
        />
    </svg>
);

export default memo(FeaturesIcon);
