import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/atoms/PageContainer';
import { Button } from '../components/shared/Button';

const NoRights: React.FC = () => {
    const { t } = useTranslation();

    const { logout } = useAuth0();

    const onSignOut = useCallback<() => void>(() => {
        logout({ returnTo: window.origin });
    }, [logout]);

    return (
        <PageContainer>
            <Box flex={1} display="flex" alignItems="center" justifyContent="center">
                <Button variant="contained" onClick={onSignOut}>
                    <Typography variant="subtitle1" fontWeight={500}>
                        {t('button.logIn')}
                    </Typography>
                </Button>
            </Box>
        </PageContainer>
    );
};

export default memo(NoRights);
