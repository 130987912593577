import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EmptyState: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Box flex="1" display="flex" justifyContent="center" alignItems="center" alignSelf="center">
            <Typography variant="body2">{t('common.noData')}</Typography>
        </Box>
    );
};

export default memo(EmptyState);
