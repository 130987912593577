import { Box, TableCellProps } from '@mui/material';
import React, { memo, useMemo } from 'react';
import AtomHeaderCell from '../atoms/TableHeaderCell';
import ArrowBottomIcon from '../icons/ArrowBottomIcon';
import ArrowTopIcon from '../icons/ArrowTopIcon';

type TableHeaderCellProps = React.PropsWithChildren<
    TableCellProps & {
        sortable?: boolean;
        topIconActive?: boolean;
        bottomIconActive?: boolean;
        onSort?: () => void;
    }
>;

const sortedCellStyle: React.CSSProperties = {
    cursor: 'pointer',
};

const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
    sortable = false,
    topIconActive = false,
    bottomIconActive = false,
    onSort,
    style,
    children,
    ...props
}) => {
    const cellStyle = useMemo<React.CSSProperties | undefined>(() => (sortable ? { ...sortedCellStyle, ...style } : style), [sortable, style]);

    const SortArrows = useMemo<React.ReactElement>(
        () => (
            <Box display="flex" flexDirection="column" alignItems="center" ml="0.5rem">
                <ArrowTopIcon isActive={topIconActive} />
                <ArrowBottomIcon isActive={bottomIconActive} />
            </Box>
        ),
        [bottomIconActive, topIconActive]
    );

    return (
        <AtomHeaderCell style={cellStyle} onClick={onSort} {...props}>
            <Box display="flex" alignItems="center">
                {children}
                {sortable && SortArrows}
            </Box>
        </AtomHeaderCell>
    );
};

export default memo(TableHeaderCell);
