import React, { useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { BACKOFFICE_AUTH_DOMAIN, BACKOFFICE_AUTH_CLIENT_ID } from '../constants/env';

const authCreds = BACKOFFICE_AUTH_DOMAIN && BACKOFFICE_AUTH_CLIENT_ID;

const AuthContext: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    useEffect(() => {
        if (!authCreds) {
            console.error('Unable to create AuthContext. Auth credentials are not provided');
        }
    }, []);

    return authCreds ? (
        <Auth0Provider domain={BACKOFFICE_AUTH_DOMAIN} clientId={BACKOFFICE_AUTH_CLIENT_ID} redirectUri={window.location.origin} cacheLocation="localstorage">
            {children}
        </Auth0Provider>
    ) : (
        <>{children}</>
    );
};

export default AuthContext;
