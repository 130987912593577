import { createTheme } from '@mui/material';
import breakpoints from './breakpoints';
import palette from './palette';
import typography from './typography';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: false;
        sm: false;
        md: false;
        lg: false;
        xl: false;
        mobile: true;
        tablet: true;
        laptop: true;
        desktop: true;
    }
}

const theme = createTheme({
    palette,
    typography,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    [breakpoints.up('mobile')]: {
                        fontSize: '11px',
                    },
                    [breakpoints.up('tablet')]: {
                        fontSize: '12px',
                    },
                    [breakpoints.up('laptop')]: {
                        fontSize: '14px',
                    },
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '& .Mui-selected': {
                        fontWeight: '700',
                    },
                    '& ul > li:not(:first-of-type):not(:last-of-type) > button:not(.Mui-selected)': {
                        color: '#919094',
                    },
                    '& ul > li > button': {
                        paddingTop: '0.14rem',
                        paddingBottom: '0.14rem',
                        paddingLeft: '0.53rem',
                        paddingRight: '0.53rem',
                        fontSize: '1rem',
                    },
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    zIndex: 1401,
                },
            },
        },
    },
});

export default theme;
