import * as React from 'react';
import { SVGProps, memo } from 'react';

const DeleteUserIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1.71rem" height="1.71rem" fill="none" {...props}>
        <path
            d="M13.5 8c0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4 4-1.79 4-4Zm-2 0c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2ZM1.5 18v2h16v-2c0-2.66-5.33-4-8-4s-8 1.34-8 4Zm2 0c.2-.71 3.3-2 6-2 2.69 0 5.77 1.28 6 2h-12ZM22.5 10h-6v2h6v-2Z"
            fill="#5E5E62"
        />
    </svg>
);

export default memo(DeleteUserIcon);
