import { Palette } from '@mui/material';
import * as R from 'ramda';
import { Badge } from '../mocks/userBadges';

/**
 * Takes an array of table columns and all columns ratio values, returns a sum of the passed columns ratio values
 * @param columns T[]
 * @param columnsRatio Record<T, number>
 * @returns a sum of the passed columns ratio values
 */
export function getColGroupSum<T extends string>(columns: T[], columnsRatio: Record<T, number>): number {
    const sum = R.reduce((acc, columnName) => acc + columnsRatio[columnName], 0, columns);

    return sum;
}

// R.concat(R.__, '%'): R.__ is a placeholder function, that allows us to pass the result from the previous function as a first argument, not the last one
// For example: R.concat('%')(value) === R.concat('%', value) and R.concat(R.__, '%')(value) === R.concat(value, '%')
/**
 * Takes column width number
 * @param value column width
 * @returns apply functions to value in that order: convert to String and concat a value with '%' str
 */
export const getColumnWidth = (value: number) => R.pipe(R.toString, R.concat(R.__, '%'))(value);

/**
 * Takes a badge variant and a palette object and returns a color
 * @param variant Badge variant
 * @param palette Palette from the theme object
 * @returns returns a badge color
 */
export const getBadgeBackroundColor = (variant: Badge, palette: Palette) => {
    switch (variant) {
        case 'upmin':
            return palette.secondary[100];
        case 'unpaid':
            return palette.secondary[200];
        case 'subscribed':
            return palette.secondary[300];
        default:
            break;
    }
};
