import { styled, PopoverProps } from '@mui/material';
import React, { memo } from 'react';
import { Popover } from '../atoms/Popover';
import ThreeDotsIcon from '../icons/ThreeDotsIcon';
import { Button } from '../shared/Button';

const anchorOrigin: PopoverProps['anchorOrigin'] = { vertical: 'bottom', horizontal: 'right' };

const transformOrigin: PopoverProps['transformOrigin'] = { vertical: 'top', horizontal: 'right' };

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: 'auto',
    padding: '0.714rem',
    marginRight: '0.857rem',
    borderRadius: '0.285rem',
    border: `1px solid ${theme.palette.info[700]}`,
    '&:hover': {
        border: `1px solid ${theme.palette.info[900]}`,
        backgroundColor: theme.palette.primary[100],
    },
}));

interface TableActionsButtonProps extends React.PropsWithChildren<{}> {
    anchor: HTMLElement | null;
    onAnchorSet: React.MouseEventHandler<HTMLElement>;
    onAnchorDestroy: () => void;
}

const TableActionsButton: React.FC<TableActionsButtonProps> = ({ anchor, onAnchorSet, onAnchorDestroy, children }) => (
    <>
        <StyledButton variant="outlined" onClick={onAnchorSet}>
            <ThreeDotsIcon />
        </StyledButton>

        <Popover id="row-menu-appbar" open={!!anchor} anchorEl={anchor} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} onClose={onAnchorDestroy}>
            {children}
        </Popover>
    </>
);

export default memo(TableActionsButton);
