import { Box, styled } from '@mui/material';

const PageContainer = styled(Box)(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
}));

export default PageContainer;
