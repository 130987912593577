import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type FeaturePayload = {
  __typename?: 'FeaturePayload';
  features: Array<Scalars['String']>;
  id: Scalars['ID'];
};

export type Landlord = {
  __typename?: 'Landlord';
  authIds: Array<Scalars['String']>;
  blocked: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  features: Array<Scalars['String']>;
  finApiUserName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastLogin: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  propertiesCount: Scalars['Int'];
  test: Scalars['Boolean'];
};

export type LandlordsPayload = {
  __typename?: 'LandlordsPayload';
  items: Array<Landlord>;
  pagination: PaginactionPayload;
};

export type Mutation = {
  __typename?: 'Mutation';
  blockLandlord: Landlord;
  createFeature: FeaturePayload;
  deleteFeature: FeaturePayload;
  deleteLandlord: Scalars['String'];
  updateLandlord: Landlord;
};


export type MutationBlockLandlordArgs = {
  blocked: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationCreateFeatureArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationDeleteFeatureArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationDeleteLandlordArgs = {
  executorEmail: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationUpdateLandlordArgs = {
  data: UpdateLandlordDataInput;
  where: UpdateLandlordWhereInput;
};

export type PaginactionPayload = {
  __typename?: 'PaginactionPayload';
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  checkRights: Scalars['Boolean'];
  healthCheck: Scalars['Int'];
  landlord?: Maybe<Landlord>;
  landlords: LandlordsPayload;
};


export type QueryCheckRightsArgs = {
  email: Scalars['String'];
};


export type QueryLandlordArgs = {
  id: Scalars['ID'];
};


export type QueryLandlordsArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type UpdateLandlordDataInput = {
  is_test?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateLandlordWhereInput = {
  id: Scalars['ID'];
};

export type LandlordFragment = { __typename?: 'Landlord', id: string, email: string, authIds: Array<string>, createdAt: any, lastLogin: any, propertiesCount: number, finApiUserName?: string | null, features: Array<string>, test: boolean, blocked: boolean };

export type CreateFeatureMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreateFeatureMutation = { __typename?: 'Mutation', createFeature: { __typename?: 'FeaturePayload', id: string, features: Array<string> } };

export type DeleteFeatureMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type DeleteFeatureMutation = { __typename?: 'Mutation', deleteFeature: { __typename?: 'FeaturePayload', id: string, features: Array<string> } };

export type DeleteLandlordMutationVariables = Exact<{
  id: Scalars['ID'];
  executorEmail: Scalars['String'];
}>;


export type DeleteLandlordMutation = { __typename?: 'Mutation', deleteLandlord: string };

export type SetLandlordBlockedMutationVariables = Exact<{
  id: Scalars['String'];
  blocked: Scalars['Boolean'];
}>;


export type SetLandlordBlockedMutation = { __typename?: 'Mutation', blockLandlord: { __typename?: 'Landlord', id: string, blocked: boolean } };

export type SetLandlordTestMutationVariables = Exact<{
  id: Scalars['ID'];
  test: Scalars['Boolean'];
}>;


export type SetLandlordTestMutation = { __typename?: 'Mutation', updateLandlord: { __typename?: 'Landlord', id: string, test: boolean } };

export type CheckRightsQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckRightsQuery = { __typename?: 'Query', checkRights: boolean };

export type FeaturesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FeaturesQuery = { __typename?: 'Query', landlord?: { __typename?: 'Landlord', id: string, features: Array<string> } | null };

export type LandlordsQueryVariables = Exact<{
  take: Scalars['Int'];
  page: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
}>;


export type LandlordsQuery = { __typename?: 'Query', landlords: { __typename?: 'LandlordsPayload', items: Array<{ __typename?: 'Landlord', id: string, email: string, authIds: Array<string>, createdAt: any, lastLogin: any, propertiesCount: number, finApiUserName?: string | null, features: Array<string>, test: boolean, blocked: boolean }>, pagination: { __typename?: 'PaginactionPayload', totalPages: number } } };

export const LandlordFragmentDoc = gql`
    fragment Landlord on Landlord {
  id
  email
  authIds
  createdAt
  lastLogin
  propertiesCount
  finApiUserName
  features
  test
  blocked
}
    `;
export const CreateFeatureDocument = gql`
    mutation createFeature($id: ID!, $name: String!) {
  createFeature(id: $id, name: $name) {
    id
    features
  }
}
    `;
export type CreateFeatureMutationFn = Apollo.MutationFunction<CreateFeatureMutation, CreateFeatureMutationVariables>;

/**
 * __useCreateFeatureMutation__
 *
 * To run a mutation, you first call `useCreateFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeatureMutation, { data, loading, error }] = useCreateFeatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateFeatureMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeatureMutation, CreateFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeatureMutation, CreateFeatureMutationVariables>(CreateFeatureDocument, options);
      }
export type CreateFeatureMutationHookResult = ReturnType<typeof useCreateFeatureMutation>;
export type CreateFeatureMutationResult = Apollo.MutationResult<CreateFeatureMutation>;
export type CreateFeatureMutationOptions = Apollo.BaseMutationOptions<CreateFeatureMutation, CreateFeatureMutationVariables>;
export const DeleteFeatureDocument = gql`
    mutation deleteFeature($id: ID!, $name: String!) {
  deleteFeature(id: $id, name: $name) {
    id
    features
  }
}
    `;
export type DeleteFeatureMutationFn = Apollo.MutationFunction<DeleteFeatureMutation, DeleteFeatureMutationVariables>;

/**
 * __useDeleteFeatureMutation__
 *
 * To run a mutation, you first call `useDeleteFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeatureMutation, { data, loading, error }] = useDeleteFeatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDeleteFeatureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeatureMutation, DeleteFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeatureMutation, DeleteFeatureMutationVariables>(DeleteFeatureDocument, options);
      }
export type DeleteFeatureMutationHookResult = ReturnType<typeof useDeleteFeatureMutation>;
export type DeleteFeatureMutationResult = Apollo.MutationResult<DeleteFeatureMutation>;
export type DeleteFeatureMutationOptions = Apollo.BaseMutationOptions<DeleteFeatureMutation, DeleteFeatureMutationVariables>;
export const DeleteLandlordDocument = gql`
    mutation deleteLandlord($id: ID!, $executorEmail: String!) {
  deleteLandlord(id: $id, executorEmail: $executorEmail)
}
    `;
export type DeleteLandlordMutationFn = Apollo.MutationFunction<DeleteLandlordMutation, DeleteLandlordMutationVariables>;

/**
 * __useDeleteLandlordMutation__
 *
 * To run a mutation, you first call `useDeleteLandlordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLandlordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLandlordMutation, { data, loading, error }] = useDeleteLandlordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      executorEmail: // value for 'executorEmail'
 *   },
 * });
 */
export function useDeleteLandlordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLandlordMutation, DeleteLandlordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLandlordMutation, DeleteLandlordMutationVariables>(DeleteLandlordDocument, options);
      }
export type DeleteLandlordMutationHookResult = ReturnType<typeof useDeleteLandlordMutation>;
export type DeleteLandlordMutationResult = Apollo.MutationResult<DeleteLandlordMutation>;
export type DeleteLandlordMutationOptions = Apollo.BaseMutationOptions<DeleteLandlordMutation, DeleteLandlordMutationVariables>;
export const SetLandlordBlockedDocument = gql`
    mutation setLandlordBlocked($id: String!, $blocked: Boolean!) {
  blockLandlord(id: $id, blocked: $blocked) {
    id
    blocked
  }
}
    `;
export type SetLandlordBlockedMutationFn = Apollo.MutationFunction<SetLandlordBlockedMutation, SetLandlordBlockedMutationVariables>;

/**
 * __useSetLandlordBlockedMutation__
 *
 * To run a mutation, you first call `useSetLandlordBlockedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLandlordBlockedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLandlordBlockedMutation, { data, loading, error }] = useSetLandlordBlockedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      blocked: // value for 'blocked'
 *   },
 * });
 */
export function useSetLandlordBlockedMutation(baseOptions?: Apollo.MutationHookOptions<SetLandlordBlockedMutation, SetLandlordBlockedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLandlordBlockedMutation, SetLandlordBlockedMutationVariables>(SetLandlordBlockedDocument, options);
      }
export type SetLandlordBlockedMutationHookResult = ReturnType<typeof useSetLandlordBlockedMutation>;
export type SetLandlordBlockedMutationResult = Apollo.MutationResult<SetLandlordBlockedMutation>;
export type SetLandlordBlockedMutationOptions = Apollo.BaseMutationOptions<SetLandlordBlockedMutation, SetLandlordBlockedMutationVariables>;
export const SetLandlordTestDocument = gql`
    mutation setLandlordTest($id: ID!, $test: Boolean!) {
  updateLandlord(where: {id: $id}, data: {is_test: $test}) {
    id
    test
  }
}
    `;
export type SetLandlordTestMutationFn = Apollo.MutationFunction<SetLandlordTestMutation, SetLandlordTestMutationVariables>;

/**
 * __useSetLandlordTestMutation__
 *
 * To run a mutation, you first call `useSetLandlordTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLandlordTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLandlordTestMutation, { data, loading, error }] = useSetLandlordTestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      test: // value for 'test'
 *   },
 * });
 */
export function useSetLandlordTestMutation(baseOptions?: Apollo.MutationHookOptions<SetLandlordTestMutation, SetLandlordTestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLandlordTestMutation, SetLandlordTestMutationVariables>(SetLandlordTestDocument, options);
      }
export type SetLandlordTestMutationHookResult = ReturnType<typeof useSetLandlordTestMutation>;
export type SetLandlordTestMutationResult = Apollo.MutationResult<SetLandlordTestMutation>;
export type SetLandlordTestMutationOptions = Apollo.BaseMutationOptions<SetLandlordTestMutation, SetLandlordTestMutationVariables>;
export const CheckRightsDocument = gql`
    query checkRights($email: String!) {
  checkRights(email: $email)
}
    `;

/**
 * __useCheckRightsQuery__
 *
 * To run a query within a React component, call `useCheckRightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckRightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckRightsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckRightsQuery(baseOptions: Apollo.QueryHookOptions<CheckRightsQuery, CheckRightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckRightsQuery, CheckRightsQueryVariables>(CheckRightsDocument, options);
      }
export function useCheckRightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckRightsQuery, CheckRightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckRightsQuery, CheckRightsQueryVariables>(CheckRightsDocument, options);
        }
export type CheckRightsQueryHookResult = ReturnType<typeof useCheckRightsQuery>;
export type CheckRightsLazyQueryHookResult = ReturnType<typeof useCheckRightsLazyQuery>;
export type CheckRightsQueryResult = Apollo.QueryResult<CheckRightsQuery, CheckRightsQueryVariables>;
export const FeaturesDocument = gql`
    query features($id: ID!) {
  landlord(id: $id) {
    id
    features
  }
}
    `;

/**
 * __useFeaturesQuery__
 *
 * To run a query within a React component, call `useFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeaturesQuery(baseOptions: Apollo.QueryHookOptions<FeaturesQuery, FeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeaturesQuery, FeaturesQueryVariables>(FeaturesDocument, options);
      }
export function useFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturesQuery, FeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeaturesQuery, FeaturesQueryVariables>(FeaturesDocument, options);
        }
export type FeaturesQueryHookResult = ReturnType<typeof useFeaturesQuery>;
export type FeaturesLazyQueryHookResult = ReturnType<typeof useFeaturesLazyQuery>;
export type FeaturesQueryResult = Apollo.QueryResult<FeaturesQuery, FeaturesQueryVariables>;
export const LandlordsDocument = gql`
    query landlords($take: Int!, $page: Int!, $email: String, $id: String) {
  landlords(take: $take, page: $page, email: $email, id: $id) {
    items {
      ...Landlord
    }
    pagination {
      totalPages
    }
  }
}
    ${LandlordFragmentDoc}`;

/**
 * __useLandlordsQuery__
 *
 * To run a query within a React component, call `useLandlordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandlordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandlordsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      page: // value for 'page'
 *      email: // value for 'email'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLandlordsQuery(baseOptions: Apollo.QueryHookOptions<LandlordsQuery, LandlordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LandlordsQuery, LandlordsQueryVariables>(LandlordsDocument, options);
      }
export function useLandlordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LandlordsQuery, LandlordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LandlordsQuery, LandlordsQueryVariables>(LandlordsDocument, options);
        }
export type LandlordsQueryHookResult = ReturnType<typeof useLandlordsQuery>;
export type LandlordsLazyQueryHookResult = ReturnType<typeof useLandlordsLazyQuery>;
export type LandlordsQueryResult = Apollo.QueryResult<LandlordsQuery, LandlordsQueryVariables>;