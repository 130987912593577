import { createHttpLink } from '@apollo/client';
import { onError as onErrorLink } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import * as R from 'ramda';
import { GraphQLErrors } from '@apollo/client/errors';
import { APOLLO_AUTH_ERROR, APOLLO_LOGIN_REQUIRED_ERROR } from '../constants/apollo';
import { Maybe } from '../graphql';

const includesAuthHeaderError = (graphQLErrors: GraphQLErrors | undefined): boolean => R.any(R.propEq('message', APOLLO_AUTH_ERROR), graphQLErrors ?? []);
const includesLoginRequiredError = (graphQLErrors: GraphQLErrors | undefined): boolean =>
    R.any(R.propEq('message', APOLLO_LOGIN_REQUIRED_ERROR), graphQLErrors ?? []);

/**
 * Takes function to get an access token and catch apollo errors
 * @param getAccessToken () => Maybe<string>)
 * @returns forward(operation) if catches auth header error otherwise void
 */
export const makeErrorLink = (getAccessToken: () => Maybe<string>) =>
    onErrorLink(({ graphQLErrors, operation, forward }) => {
        if (includesAuthHeaderError(graphQLErrors)) {
            makeAuthLink(getAccessToken);

            return forward(operation);
        }

        if (includesLoginRequiredError(graphQLErrors)) {
            return;
        }

        console.error(graphQLErrors?.concat()?.toString() ?? '');
    });

/**
 * Takes graphql uri and returns created apollo link
 * @param uri graphql API uri
 * @returns created http link
 */
export const makeHttpLink = (uri: string | undefined) => R.pipe(R.objOf('uri'), createHttpLink)(uri);

/**
 * Sets the authorization header to the apollo context
 * @param getAccessToken function that returns an actual access token or null
 * @returns updated apollo context
 */
export const makeAuthLink = (getAccessToken: () => string | null) =>
    setContext((_, context) => {
        const accessToken = getAccessToken();

        return accessToken ? R.set(R.lensPath(['headers', 'Authorization']), `Bearer ${accessToken}`, context) : context;
    });
