import React, { memo, useCallback, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, styled, Typography, CheckboxProps } from '@mui/material';

export type SelectButtonProps = {
    value: boolean;
    label: string;
    onChange: (checked: boolean) => void;
} & Omit<CheckboxProps, 'onChange' | 'value'>;

const StyledCheckbox = styled(Checkbox)(() => ({
    padding: 0,
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    margin: 0,
}));

const Container = styled(Box)(({ theme }) => ({
    padding: '0.57rem 1.14rem',
    '&:hover': {
        backgroundColor: theme.palette.primary[900],
    },
}));

const SelectButton: React.FC<SelectButtonProps> = ({ value, label, onChange, ...props }) => {
    const Label = useMemo<React.ReactElement>(
        () => (
            <Typography variant="body2" color={value ? 'primary.main' : undefined} ml="1.28rem">
                {label}
            </Typography>
        ),
        [label, value]
    );

    const onCheckboxChange = useCallback<NonNullable<CheckboxProps['onChange']>>(({ target: { checked } }) => onChange(checked), [onChange]);

    const Control = useMemo<React.ReactElement>(
        () => <StyledCheckbox checked={value} onChange={onCheckboxChange} name={label} {...props} />,
        [value, onCheckboxChange, label, props]
    );

    return (
        <Container>
            <StyledFormControlLabel control={Control} label={Label} />
        </Container>
    );
};

export default memo(SelectButton);
