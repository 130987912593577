import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({
    values: {
        mobile: 0,
        tablet: 768,
        laptop: 1024,
        desktop: 1280,
    },
});

export default breakpoints;
