import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import App from './App';
import ApolloClientContext from './contexts/ApolloClientContext';
import AuthContext from './contexts/AuthContext';
import I18Context from './contexts/i18Context';
import TableContextProvider from './contexts/TableContext';
import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <AuthContext>
            <ApolloClientContext>
                <I18Context>
                    <TableContextProvider>
                        <ThemeProvider theme={theme}>
                            <App />
                        </ThemeProvider>
                    </TableContextProvider>
                </I18Context>
            </ApolloClientContext>
        </AuthContext>
    </React.StrictMode>
);
