import * as React from 'react';
import { SVGProps, memo } from 'react';

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="0.714rem" height="1.14rem" fill="none" {...props}>
        <path d="M.586 14.172 1.914 15.5l7.5-7.5-7.5-7.5L.586 1.827 6.76 8 .586 14.172Z" fill="#5E5E62" />
    </svg>
);

export default memo(ArrowRightIcon);
