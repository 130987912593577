import React, { memo } from 'react';
import { Box } from '@mui/material';
import Loader from '../components/atoms/Loader';

const Loading: React.FC = () => (
    <Box flex="1" display="flex" justifyContent="center" alignItems="center" alignSelf="center">
        <Loader />
    </Box>
);

export default memo(Loading);
