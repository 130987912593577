import { Box, styled, Typography } from '@mui/material';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import PageContainer from '../components/atoms/PageContainer';
import LandlordTablePagination from '../components/LandlordTable/LandlordTablePagination';
import LandlordTableRow from '../components/LandlordTable/LandlordTableRow';
import Table from '../components/shared/Table';
import TableHeaderCell from '../components/shared/TableHeaderCell';
import { tableConfig } from '../constants/tableConfig';
import { useTableContext } from '../contexts/TableContext';
import { LandlordFragment } from '../graphql';
import { RoutesKeys } from '../navigation/routes';
import { ModalOutletContextType } from '../types/OutletContext';
import TableColumnType from '../types/TableColumn';

const outletContext: ModalOutletContextType = {
    parentPath: RoutesKeys.Home,
};

const HeaderCell = styled(TableHeaderCell)(() => ({
    '&:last-of-type div': {
        justifyContent: 'center',
    },
}));

const Home: React.FC = () => {
    const { t } = useTranslation();

    const { rows, visibleColumns, loading } = useTableContext();

    const renderRow = useCallback<(row: LandlordFragment, index: number) => React.ReactElement>((row) => <LandlordTableRow key={row.id} row={row} />, []);

    const renderHeaderCell = useCallback<(key: TableColumnType) => React.ReactElement>(
        (key) => (
            <HeaderCell key={key}>
                <Typography variant="subtitle1">{t(key)}</Typography>
            </HeaderCell>
        ),
        [t]
    );

    return (
        <>
            <PageContainer>
                <Box flex={1} display="flex" alignItems="flex-start">
                    <Table
                        loading={loading}
                        columns={visibleColumns}
                        columnsRatio={tableConfig.columnsRatio}
                        rows={rows}
                        renderRow={renderRow}
                        renderHeaderCell={renderHeaderCell}
                    />
                </Box>
                <LandlordTablePagination />
            </PageContainer>
            <Outlet context={outletContext} />
        </>
    );
};

export default memo(Home);
