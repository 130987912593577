import { getOperationName } from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, styled, Typography } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { LandlordFragment, LandlordsDocument, useDeleteLandlordMutation, useSetLandlordBlockedMutation, useSetLandlordTestMutation } from '../../graphql';
import useUpdateFragment from '../../hooks/useUpdateFragment';
import { RoutesKeys } from '../../navigation/routes';
import DeleteUserIcon from '../icons/DeleteUserIcon';
import FeaturesIcon from '../icons/FeaturesIcon';
import SelectButton, { SelectButtonProps } from '../shared/SelectButton';
import { PopoverBox } from '../atoms/PopoverBox';
import { PopoverButton } from '../atoms/PopoverButton';
import useDialog from '../../hooks/useDialog';
import { Button } from '../shared/Button';
import { Input } from '../atoms/Input';

interface LandlordActionsContentProps {
    row: LandlordFragment;
    onAnchorDestroy: () => void;
}

const Divider = styled(Box)(({ theme }) => ({
    alignSelf: 'stretch',
    height: '0.07rem',
    backgroundColor: theme.palette.primary[100],
}));

const ConfrimDelete = styled('div')(({ theme }) => ({
    minWidth: '33vw',
}));

const StyledInput = styled(Input)(() => ({
    border: '1px solid #ccc',
    width: '100%',
    margin: '10px 0 0 0',
    padding: '5px',
}));

const LandlordActionsContent: React.FC<LandlordActionsContentProps> = ({ row, onAnchorDestroy }) => {
    const { t } = useTranslation();

    const [userInput, setUserInput] = useState('');

    const navigate = useNavigate();

    const { user } = useAuth0();

    const { isOpened, onOpenDialog, onCloseDialog } = useDialog();

    const { onSetLandlordBlocked, onSetLandlordTest } = useUpdateFragment();

    const [setBlocked, { loading: setBlockedLoading }] = useSetLandlordBlockedMutation({
        onCompleted: onSetLandlordBlocked,
    });

    const [setTest, { loading: setTestLoading }] = useSetLandlordTestMutation({
        onCompleted: onSetLandlordTest,
    });
    const [remove, { loading: deleteLoading }] = useDeleteLandlordMutation({
        refetchQueries: [getOperationName(LandlordsDocument) ?? ''],
    });

    const onFeatures = useCallback<() => void>(() => {
        onAnchorDestroy();
        navigate(generatePath(RoutesKeys.Features, { userId: row.id }), { replace: true });
    }, [onAnchorDestroy, navigate, row.id]);

    const onSetAsTestUserClick = useCallback<SelectButtonProps['onChange']>(
        (checked) => setTest({ variables: { id: row.id, test: checked } }),
        [row.id, setTest]
    );

    const onBlockUserClick = useCallback<SelectButtonProps['onChange']>(
        (checked) => setBlocked({ variables: { id: row.id, blocked: checked } }),
        [row.id, setBlocked]
    );

    const onDeleteConfirm = useCallback<() => void>(() => {
        if (user?.email) {
            remove({ variables: { id: row.id, executorEmail: user.email } });
        }

        onCloseDialog();
    }, [onCloseDialog, remove, row.id, user?.email]);

    return (
        <PopoverBox>
            <PopoverButton variant="text" disabled={deleteLoading} onClick={onOpenDialog}>
                <DeleteUserIcon />
                <Typography variant="body2" color="info.200" ml="1.14rem">
                    {t('action.delete')}
                </Typography>
            </PopoverButton>

            <Divider />

            <SelectButton value={row.test} onChange={onSetAsTestUserClick} disabled={setTestLoading} label={t('action.setAsTestUser')} />

            <SelectButton value={row.blocked} onChange={onBlockUserClick} disabled={setBlockedLoading} label={t('action.blockUser')} />

            <Divider />

            <PopoverButton variant="text" onClick={onFeatures}>
                <FeaturesIcon />
                <Typography variant="body2" color="info.200" ml="1.14rem">
                    {t('action.adjustFeaturesShown')}
                </Typography>
            </PopoverButton>

            <Dialog open={isOpened} onClose={onCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <ConfrimDelete>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="body2">{t('action.deleteConfirmation')}</Typography>
                            <StyledInput type="text" onChange={(e) => setUserInput(e.target.value)} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onCloseDialog}>
                            <Typography variant="subtitle1">{t('button.cancel')}</Typography>
                        </Button>
                        <Button onClick={onDeleteConfirm} autoFocus disabled={row.email !== userInput}>
                            <Typography variant="subtitle1">{t('button.confirm')}</Typography>
                        </Button>
                    </DialogActions>
                </ConfrimDelete>
            </Dialog>
        </PopoverBox>
    );
};

export default memo(LandlordActionsContent);
