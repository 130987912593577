import React, { memo, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading';

const Login: React.FC = () => {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        loginWithRedirect();
    }, [loginWithRedirect]);

    return <Loading />;
};

export default memo(Login);
