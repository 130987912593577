import React, { useEffect, useState } from 'react';
import { LandlordFragment } from '../graphql';
import useLandlords from '../hooks/useLandlords';
import useTableConfig from '../hooks/useTableConfig';
import TableColumnType from '../types/TableColumn';
import { TableConfigColumnsType } from '../types/tableConfig';
import { SearchField } from '../types/tableFilters';
import createContext from '../utils/createContext';

export interface TableContextType {
    rows: LandlordFragment[];
    pagesCount: number;
    loading: boolean;
    page: number;
    setPage: (value: number) => void;
    pageSize: number;
    setPageSize: (value: number) => void;
    columns: TableConfigColumnsType;
    visibleColumns: TableColumnType[];
    setColumnVisibility: (column: TableColumnType, visible: boolean) => void;
    onSearch: (email: string, variant: SearchField) => void;
}

export const [Context, useTableContext] = createContext<TableContextType>('TableContext');

export const TableContextProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [page, setPage] = useState<number>(0);

    const tableConfig = useTableConfig();

    const landlords = useLandlords({
        page,
        take: tableConfig.pageSize,
    });

    useEffect(() => setPage(0), [landlords.filter, tableConfig.pageSize]);

    return (
        <Context.Provider
            value={{
                page,
                setPage,
                ...landlords,
                ...tableConfig,
            }}>
            {children}
        </Context.Provider>
    );
};

export default TableContextProvider;
