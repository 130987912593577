import { Checkbox, FormControl, FormLabel, styled, CheckboxProps } from '@mui/material';
import React, { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateFeatureMutation, useDeleteFeatureMutation } from '../../graphql';
import useUpdateFragment from '../../hooks/useUpdateFragment';

interface FeaturesListItemProps {
    feature: string;
    checked: boolean;
}

const StyledFormLabel = styled(FormLabel)({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1rem',
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '0.6rem',
    paddingBottom: '0.6rem',
    '&:hover': {
        backgroundColor: theme.palette.primary[900],
    },
}));

const FeaturesListItem: React.FC<FeaturesListItemProps> = ({ feature, checked }) => {
    const { userId } = useParams();

    const { onCreateFeature, onDeleteFeature } = useUpdateFragment();

    const [create, { loading: createLoading }] = useCreateFeatureMutation({
        onCompleted: onCreateFeature,
    });

    const [remove, { loading: removeLoading }] = useDeleteFeatureMutation({
        onCompleted: onDeleteFeature,
    });

    const onCheckboxChange = useCallback<NonNullable<CheckboxProps['onChange']>>(
        (_, checked) => {
            if (userId) {
                const options = { variables: { id: userId, name: feature } };
                if (checked) create(options);
                else remove(options);
            }
        },
        [userId, feature, create, remove]
    );

    return (
        <StyledFormControl>
            <StyledFormLabel>{feature}</StyledFormLabel>
            <Checkbox disabled={createLoading || removeLoading} checked={checked} onChange={onCheckboxChange} />
        </StyledFormControl>
    );
};

export default memo(FeaturesListItem);
