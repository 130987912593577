import { Box, styled } from '@mui/material';

export const PopoverBox = styled(Box)(({ theme }) => ({
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '0.28rem',
    paddingTop: '0.57rem',
    paddingBottom: '0.57rem',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    ' button': {
        minWidth: '9.2rem',
        justifyContent: 'flex-start',
        padding: '0.57rem 1.14rem',
    },
}));
