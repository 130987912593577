import { Typography } from '@mui/material';
import React, { memo } from 'react';
import TableCell from '../atoms/TableCell';

interface TableDefaultCellProps {
    value: string | number;
}

const TableDefaultCell: React.FC<TableDefaultCellProps> = ({ value }) => (
    <TableCell>
        <Typography variant="subtitle1" color="info.300">
            {value}
        </Typography>
    </TableCell>
);

export default memo(TableDefaultCell);
