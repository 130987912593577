import React, { memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingGuard from './LoadingGuard';
import { RoutesKeys } from './routes';

export interface AuthorizationGuardProps {
    children: React.ReactElement | JSX.Element;
    authorized?: boolean;
}

const AuthorizationGuard: React.FC<AuthorizationGuardProps> = ({ children, authorized = false }) => {
    const { user, isLoading } = useAuth0();

    const isValid = (authorized && !!user) || (!authorized && !user);
    const onInvalidPath = authorized ? RoutesKeys.Login : RoutesKeys.Home;

    return (
        <LoadingGuard isValid={isValid} loading={isLoading} onInvalidPath={onInvalidPath}>
            {children}
        </LoadingGuard>
    );
};

export default memo(AuthorizationGuard);
